<template>
    <div align="left" justify="center" class="pa-3">
        <Loading :active="loadingInicial" :is-full-page="true" />
        <v-card align="left" justify="left">
            <v-card-text class="pa-3 font-weight-light white black--text">
                <v-icon class="primary--text lighten-2 pa-2">
                    mdi-chart-box </v-icon
                >Relatórios > Financeiro > Repasses e taxas
            </v-card-text>
        </v-card>
        <v-card class="">
            <v-row align="center" class="mx-3 mt-3 py-4">
                <v-col lg="12" class="px-1 py-3">
                    <v-card-text class="pa-0"
                        ><v-icon class="mx-2">mdi-information-outline</v-icon
                        >Utilize os campos abaixo para aplicar filtros na
                        pesquisa, após selecionar clique em Exportar
                        Resultados</v-card-text
                    >
                </v-col>
                <v-col cols="4" xs="2" sm="2" md="3" lg="3">
                    <v-text-field
                        clearable
                        solo
                        small
                        hint="Informe o Código do título"
                        prepend-icon="mdi-chevron-right"
                        label="Código do título"
                        placeholder="Titulo"
                        elevation="0"
                        hide-details="auto"
                        v-model="filtros.ds_nosso_numero"
                        @keydown.enter="filtrar()"
                    ></v-text-field>
                </v-col>
                <v-col cols="4" xs="2" sm="2" md="3" lg="3">
                    <v-text-field
                        clearable
                        solo
                        small
                        hint="Informe o CPF/CNPJ"
                        label="CPF/CNPJ"
                        placeholder="Documento"
                        hide-details="auto"
                        v-mask="['###.###.###-##', 'XX.XXX.XXX/XXXX-##']"
                        onkeypress="return event.charCode >= 46 && event.charCode <= 57"
                        v-model="filtros.nu_identificacao_devedor"
                        @keydown.enter="filtrar()"
                    ></v-text-field>
                </v-col>
                <v-col cols="4" xs="2" sm="2" md="3" lg="3">
                    <v-text-field
                        clearable
                        solo
                        small
                        label="Nome do devedor"
                        hint="Informe o Nome do devedor"
                        placeholder="Nome"
                        hide-details="auto"
                        v-model="filtros.nm_devedor"
                        @keydown.enter="filtrar()"
                    ></v-text-field>
                </v-col>
                <v-col cols="4" xs="2" sm="2" md="3" lg="3">
                    <v-menu
                        solo
                        v-model="menuDataAtualizacaoRange"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                clearable
                                @click:clear="limparFiltroData()"
                                solo
                                hide-details="auto"
                                :value="computedDateAtualizacaoFormattedRange"
                                label="Data de Movimentação"
                                prepend-icon="mdi-calendar-cursor"
                                persistent-hint
                                readonly
                                v-on="on"
                            ></v-text-field>
                        </template>

                        <v-date-picker
                            v-model="filtros.dt_movimento_range"
                            range
                            selected-items-text="Datas Selecionadas"
                            color="primary"
                            locale="pt-br"
                        ></v-date-picker>
                    </v-menu>
                </v-col>
            </v-row>
            <v-row align="center" justify="end" class="mx-3 mt-3 py-4">
                <v-btn
                    class="mr-3"
                    color="primary"
                    :disabled="false"
                    :loading="loadingFilter"
                    data-cy="btnFiltrarRelatorioRepasse"

                    @click="filtrar()"
                >
                    Filtrar
                    <v-icon class="ml-2">mdi-magnify</v-icon>
                </v-btn>
            </v-row>
        </v-card>

        <v-card class="mt-6">
            <v-data-table
                :headers="headers"
                :items="titulos"
                :options.sync="options"
                :server-items-length="totalTitulos"
                :footer-props="{
                    itemsPerPageOptions: [10, 50, 100, 500, -1],
                    itemsPerPageText: 'Itens por página:'
                }"
                :loading="loadingTable"
                loading-text="Pesquisando títulos..."
                no-data-text="Nenhum resultado encontrado... Faça uma pesquisa"
                no-results-text="Nenhum resultado encontrado..."
                class="elevation-1 pa-3"
            >
                <template v-slot:[`item.ds_nosso_numero`]="{ item }">
                    <span :style="{ color: 'black' }">{{
                        item.ds_nosso_numero
                    }}</span>
                </template>
                <template v-slot:[`item.dt_cadastro`]="{ item }">
                    <span :style="{ color: 'black' }">{{
                        item.dt_cadastro
                    }}</span>
                </template>
                <template v-slot:[`item.nu_identificacao_devedor`]="{ item }">
                    <span :style="{ color: 'black' }">{{
                        formatDocumento(item)
                    }}</span>
                </template>
                <template v-slot:[`item.nm_devedor`]="{ item }">
                    <span :style="{ color: 'black' }">{{
                        item.nm_devedor
                    }}</span>
                </template>
                <template v-slot:[`item.vl_saldo_protesto`]="{ item }">
                    <span :style="{ color: 'black' }">{{
                        item.vl_saldo_protesto
                    }}</span>
                </template>
                <template v-slot:[`item.vl_repassado`]="{ item }">
                    <span :style="{ color: 'black' }">{{
                        formatarDecimal(item.vl_repassado, false)
                    }}</span>
                </template>
                <template v-slot:[`item.cd_status`]="{ item }">
                    <span :style="{ color: 'black' }">{{
                        getDescSituacao(item)
                    }}</span>
                </template>
                <template v-slot:[`item.dt_atualizacao`]="{ item }">
                    <span :style="{ color: 'black' }">{{
                        item.dt_atualizacao
                    }}</span>
                </template>
                <template v-slot:[`item.dt_arquivo_movimento`]="{ item }">
                    <span :style="{ color: 'black' }">{{
                        formatarDataHoraMoment(item.dt_arquivo_movimento)
                    }}</span>
                </template>
            </v-data-table>
        </v-card>

        <v-card class="mt-3">
            <v-row class="my-4 ml-4">
                <v-col lg="12" class="text-end">
                    <!-- :disabled="false" -->
                    <!-- <v-btn
                        class="mx-3"
                        color="primary"
                        disabled
                        :loading="loadingExport"
                        @click="exportar()"
                    >
                        Mostrar Resultados
                        <v-icon small class="ma-2">mdi-magnify</v-icon>
                    </v-btn> -->
                    <v-btn
                        class="mx-6"
                        color="primary"
                        :disabled="!titulos.length"
                        :loading="loadingExport"
                        data-cy="btnExportarRelatorioRepasse"
                        @click="exportar()"
                    >
                        Exportar resultados
                        <v-icon small class="ma-2">cloud_download</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
        <Snackbar
            v-if="snackbarDialog"
            :mostrarSnackbar="snackbarDialog"
            :corSnackbar="snackbarColor"
            :mensagemSnackbar="mensagemRetornoSnackbar"
            @fecharSnackbar="snackbarDialog = false"
        />

        <v-dialog v-model="modalReceberRelatorioEmail" max-width="500">
            <v-card>
                <v-card-text>
                    <v-card-title class="headline primary--text"
                        >Relatório via e-mail</v-card-title
                    >
                    <v-card-text v-if="filtros">
                        O relatórios será aplicado com os seguintes filtros:
                    </v-card-text>
                    <!-- linha 1 de Cabeçalhos -->
                    <v-card-text v-if="filtros.ds_numero_titulo != null">
                        <v-icon>mdi-information-outline</v-icon> Código do
                        Título: {{ filtros.ds_numero_titulo }}
                    </v-card-text>
                    <v-card-text
                        v-if="filtros.nu_identificacao_devedor != null"
                    >
                        <v-icon>mdi-information-outline</v-icon> Documento do
                        Devedor: {{ filtros.nu_identificacao_devedor }}
                    </v-card-text>
                    <v-card-text v-if="filtros.nm_devedor != null">
                        <v-icon>mdi-information-outline</v-icon> Nome do
                        Devedor: {{ filtros.nm_devedor }}
                    </v-card-text>
                    <v-card-text v-if="filtros.cd_status != null">
                        <v-icon>mdi-information-outline</v-icon> Código do
                        Status: {{ getNomeCdStatus(filtros.cd_status) }}
                    </v-card-text>
                    <!-- linha 2 de Cabeçalhos -->
                    <v-card-text
                        v-if="computedDateAtualizacaoFormattedRange != null"
                    >
                        <v-icon>mdi-information-outline</v-icon> Data de
                        atualização dos envios:
                        {{ computedDateAtualizacaoFormattedRange }}
                    </v-card-text>
                    <v-spacer></v-spacer>
                    <v-card-subtitle
                        >Digite o e-mail onde deseja receber o
                        relatório</v-card-subtitle
                    >
                    <v-form ref="formEmail" v-model="validEmail">
                        <v-text-field
                            prepend-icon="person"
                            name="email"
                            label="Digite aqui seu e-mail"
                            type="email"
                            solo
                            v-model="emailReport"
                            :rules="emailRules"
                            :loading="loadingFiltros"
                            required
                            data-cy="emailReportRepasse"
                        ></v-text-field>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        @click="exportarRelatoriosMsgs()"
                        :disabled="checkForm()"
                        data-cy="btnEnviarRelatorioRepasse"
                    >
                        Enviar Relatório
                        <v-icon class="ml-1">chevron_right</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="mostraConfirmacaoEmail" max-width="500">
            <v-card>
                <v-card-text>
                    <v-card-title class="headline primary--text"
                        >Relatório solicitado com sucesso.</v-card-title
                    >
                    <v-spacer></v-spacer>
                    <v-card-subtitle
                        >O relatório será enviado para o seu
                        e-mail!</v-card-subtitle
                    >
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="green darken-1"
                        text
                        v-show="mostraConfirmacaoEmail"
                        @click="mostraConfirmacaoEmail = false"
                        >Ok!</v-btn
                    >
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import Vue from 'vue';
import TituloService from '@/services/TituloService';
import * as Formatter from '@/helpers/Formatter';
import {
    getOnlyNumbers,
    formatarDecimal,
    formatarDataHoraMoment
} from '@/helpers/Utilitarios';
import lodash from 'lodash';
import Loading from 'vue-loading-overlay';

export default {
    name: 'PDFConsulta',
    components: { Loading },
    data() {
        return {
            headers: [
                {
                    text: 'Número do título',
                    sortable: true,
                    value: 'ds_nosso_numero'
                },
                {
                    text: 'Data de envio',
                    align: 'left',
                    sortable: true,
                    value: 'dt_cadastro'
                },
                {
                    text: 'CPF/CNPJ',
                    sortable: true,
                    value: 'nu_identificacao_devedor'
                },
                { text: 'Nome', sortable: true, value: 'nm_devedor' },
                {
                    text: 'Valor R$',
                    sortable: true,
                    value: 'vl_saldo_protesto'
                },
                {
                    text: 'Valor Repasse R$',
                    sortable: true,
                    value: 'vl_repassado'
                },
                { text: 'Status', sortable: false, value: 'cd_status' },
                {
                    text: 'Data de movimentação',
                    align: 'left',
                    sortable: true,
                    value: 'dt_arquivo_movimento'
                }
            ],
            titulos: [],
            totalTitulos: 0,
            options: {
                page: 1,
                itemsPerPage: 10
            },
            statusDisponiveis: [
                { cd_status: 101, nm_status: 'Pago' },
                { cd_status: 107, nm_status: 'Liquidação em condicional' }
            ],
            cd_status_aplicado: [101, 107],
            loadingInicial: false,
            snackbarColor: null,
            snackbarDialog: false,
            snackbarIcon: null,
            mensagemRetornoSnackbar: null,
            loadingFilter: false,
            loadingExport: false,
            loadingTable: false,
            loadingFiltros: false,
            menuDataAtualizacaoRange: false,
            emailReport: '',
            modalReceberRelatorioEmail: false,
            filtros: {
                cd_status: null,
                nm_devedor: null,
                cd_carteira: 'Todos',
                nu_identificacao_devedor: null,
                ds_nosso_numero: null,
                ds_numero_titulo: null,
                apontamento_nu_protocolo: null,
                dt_movimento_range: [],
                dt_movimento_range_fmt: null
            },
            emailRules: [
                v => !!v || 'E-mail é requerido',
                v => /.+@.+/.test(v) || 'E-mail inválido'
            ],
            validEmail: false,
            mostraConfirmacaoEmail: false,
            primaryColorFont: '#757575'
        };
    },

    watch: {
        options: {
            handler() {
                if (this.totalTitulos > 0) {
                    this.filtrar();
                }
            },
            deep: true
        },
        'filtros.dt_movimento_range'() {
            this.filtros.dt_movimento_range_fmt = [
                Formatter.formatData(this.filtros.dt_movimento_range[0]),
                Formatter.formatData(this.filtros.dt_movimento_range[1])
            ];
            if (
                this.filtros.dt_movimento_range[0] <
                    this.filtros.dt_movimento_range[1] ||
                this.filtros.dt_movimento_range[1] === undefined
            ) {
                this.filtros.dt_arquivo_movimento_de = this.filtros.dt_movimento_range[0];
                this.filtros.dt_arquivo_movimento_ate = this.filtros.dt_movimento_range[1];
            } else {
                this.filtros.dt_arquivo_movimento_de = this.filtros.dt_movimento_range[1];
                this.filtros.dt_arquivo_movimento_ate = this.filtros.dt_movimento_range[0];
            }
        }
    },
    computed: {
        computedDateAtualizacaoFormattedRange() {
            return this.dataAtualizacao();
        },
        listaSituacoes() {
            return this.$store.getters.situacoesTitulo;
        },
        listaCarteiras() {
            return this.$store.getters.Carteiras;
        }
    },
    async mounted() {
        this.filtros.dt_arquivo_movimento_de = this.filtros.dt_movimento_range[0];
        this.filtros.dt_arquivo_movimento_ate = this.filtros.dt_movimento_range[1];

        this.filtros.cd_status = this.cd_status_aplicado;

        this.loadingInicial = true;
        this.loadingInicial = false;

        let tituloService = new TituloService(Vue.http, this.$store);
        tituloService.listaCarteiras();

        if (this.$store.getters.isPartner == 1) {
            this.primaryColorFont = '#757575';
        } else {
            this.primaryColorFont = this.$vuetify.theme.themes.light.primary;
        }
    },
    methods: {
        getOnlyNumbers,
        formatarDecimal,
        formatarDataHoraMoment,
        limparFiltroData() {
            this.filtros.dt_movimento_range = [];

            this.filtros.dt_arquivo_movimento_de = this.filtros.dt_movimento_range[0];
            this.filtros.dt_arquivo_movimento_ate = this.filtros.dt_movimento_range[1];
        },
        getNomeCdStatus(listaCdStatus) {
            const nomeStatus = [];
            listaCdStatus.forEach(cdStatus => {
                const statusDisponivel = this.statusDisponiveis.find(
                    status => status.cd_status === cdStatus
                );
                if (statusDisponivel) {
                    nomeStatus.push(statusDisponivel.nm_status);
                }
            });
            return nomeStatus.join(', ');
        },
        verificarMascara(cpfCnpj) {
            let mascara = '###.###.###-###';
            if (cpfCnpj) {
                const cpfCnpjNumeros = this.getOnlyNumbers(cpfCnpj);
                if (cpfCnpjNumeros.length <= 11) {
                    mascara = '###.###.###-###';
                } else {
                    mascara = 'XX.XXX.XXX/XXXX-##';
                }
            }
            return mascara;
        },
        getDescSituacao(titulo) {
            return Formatter.getDescSituacaoTitulo(
                titulo.cd_status,
                this.listaSituacoes
            );
        },
        formatDocumento(titulo) {
            return Formatter.formatDocumento(
                titulo.nu_identificacao_devedor,
                titulo.tp_identificacao_devedor
            );
        },
        mostrarSnackbar(icone, cor, mensagem) {
            this.snackbarDialog = true;
            this.snackbarIcon = icone;
            this.snackbarColor = cor;
            this.mensagemRetornoSnackbar = mensagem;
        },
        filtrar() {
            let tituloService = new TituloService(Vue.http, this.$store);
            const page = this.options.page;
            const itemsPerPage = this.options.itemsPerPage;
            const filtro = lodash.cloneDeep(this.filtros);
            if (filtro.nu_identificacao_devedor) {
                filtro.nu_identificacao_devedor = this.getOnlyNumbers(
                    filtro.nu_identificacao_devedor
                );
            }
            this.loadingFilter = true;
            this.loadingTable = true;

            tituloService
                .consultarTitulosRepasse(page, itemsPerPage, filtro)
                .then(
                    response => {
                        if (response.status == 200) {
                            const {
                                titulos,
                                numero_linhas: total
                            } = response.body;

                            this.titulos = lodash.cloneDeep(titulos);
                            this.totalTitulos = lodash.cloneDeep(total);

                            this.mostrarSnackbar(
                                'mdi-text-box-check-outline',
                                'success',
                                'Busca efetuada com sucesso!'
                            );
                        }
                    },
                    error => {
                        if (error.status == 400) {
                            this.mostrarSnackbar(
                                'mdi-close',
                                'error',
                                'Erro ao executar o Filtro.'
                            );
                        }
                    }
                )
                .finally(() => {
                    this.loadingFilter = false;
                    this.loadingTable = false;
                });
        },
        dataAtualizacao() {
            this.filtros.dt_movimento_range_fmt = [
                Formatter.formatData(this.filtros.dt_movimento_range[0]),
                Formatter.formatData(this.filtros.dt_movimento_range[1])
            ];
            if (this.filtros.dt_movimento_range[1] == null) {
                return this.filtros.dt_movimento_range_fmt[0];
            } else if (
                this.filtros.dt_movimento_range_fmt[0] >
                this.filtros.dt_movimento_range_fmt[1]
            ) {
                this.filtros.dt_movimento_range_fmt = this.filtros.dt_movimento_range_fmt.reverse();
                return this.filtros.dt_movimento_range_fmt.join(' - ');
            } else {
                return this.filtros.dt_movimento_range_fmt.join(' - ');
            }
        },
        exportar() {
            this.modalReceberRelatorioEmail = true;
        },
        exportarRelatoriosMsgs() {
            // this.filtros.cd_status = this.cd_status_aplicado;
            this.filtros.cd_apresentante = this.$store.getters.cdApresentante;
            let tituloService = new TituloService(Vue.http, this.$store);
            this.loadingFiltros = true;
            var emails = [];
            emails[0] = this.emailReport;

            tituloService
                .exportarFinanceiroResumido(this.filtros, emails)
                .then(response => {
                    console.log(response);
                    this.modalReceberRelatorioEmail = false;
                    this.mostraConfirmacaoEmail = true;
                    this.emailReport = null;
                    this.loadingFiltros = false;
                })
                .catch(() => {
                    this.loadingFiltros = false;
                    this.modalEmailXls = false;
                    this.mostraConfirmacaoEmail = true;
                    this.emailReport = null;
                });
        },
        checkformSubmit() {
            this.checkForm();
        },
        checkForm() {
            if (this.validEmail) {
                return false;
            }
            return true;
        }
    }
};
</script>
